<template>
  <v-col class="px-0 py-0">
    <v-row no-gutters align="center" style="margin-top: 20px" v-if="isEdit">
      <v-col class="px-0 py-0" style="margin-right: 10px">
        <statistic-card
          :numbers="22"
          title="Проданих квитків"
          subtitle="В продажі"
        />
      </v-col>
      <v-col class="px-0 py-0" style="margin-left: 10px; margin-right: 10px">
        <statistic-card
          :numbers="12000"
          title="Сума продажів"
          subtitle="з рейсу"
        />
      </v-col>
      <v-col class="px-0 py-0" style="margin-left: 10px">
        <statistic-card
          :numbers="4"
          title="Скасованих квитка"
          subtitle="з рейсу"
        />
      </v-col>
    </v-row>
    <v-row no-gutters align="center" style="margin-top: 40px" v-if="isEdit">
      <div
        class="iconBackground"
        style="width: 44px; height: 44px; margin-right: 20px"
        @click="
          $router.push({
            name: 'pricesEditComponent',
            params: { route_id: trip?.route },
          })
        "
      >
        <div class="priceIcon" />
      </div>
      <div
        class="iconBackground"
        style="width: 44px; height: 44px; margin-right: 20px"
        @click="
          $router.push({ name: 'editRoute', params: { id: trip?.route } })
        "
      >
        <div class="routeIcon" />
      </div>
      <div
        class="iconBackground"
        style="width: 44px; height: 44px; margin-right: 20px"
        @click="
          $router.push({ name: 'editAutopark', params: { id: trip?.bus } })
        "
      >
        <div class="busIcon" />
      </div>
      <div class="iconBackground" style="width: 44px; height: 44px">
        <div class="historyIcon" />
      </div>
      <v-row no-gutters justify="end">
        <v-btn
          width="220px"
          height="48px"
          class="deleteTripBtn"
          @click="cancelTrip"
          >Скасувати квиток</v-btn
        >
      </v-row>
    </v-row>
    <div class="sectionBackground">
      <p class="sectionTitle">Основні дані рейсу</p>
      <div class="sectionLine" />
      <v-row no-gutters style="margin-top: 32px">
        <v-col cols="6">
          <v-autocomplete
            outlined
            dense
            placeholder="Виберіть тут.."
            label="Маршрут"
            height="50px"
            background-color="#FCFCFC"
            color="#144FA9"
            v-model="trip.route"
            :items="Object.values(routesList)"
            :item-text="
              (item) =>
                `${
                  item?.departure?.translations.find(
                    (translate) => translate.lang == 'ua'
                  ).name
                } - ${
                  item?.destination?.translations.find(
                    (translate) => translate.lang == 'ua'
                  ).name
                }`
            "
            :item-value="'id'"
            style="border-radius: 10px"
            :error-messages="routeError"
            :success="!$v.trip.route.$invalid"
          />
        </v-col>
        <v-col cols="6">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="isClose"
            offset-y
            nudge-left="180"
            nudge-bottom="0"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-bind="attrs"
                v-on="on"
                outlined
                dense
                placeholder="Виберіть тут.."
                label="Дата відправлення\прибуття"
                height="50px"
                background-color="#FCFCFC"
                color="#144FA9"
                v-model="trip.dates"
                style="padding-left: 20px; border-radius: 10px"
                :error-messages="datesError"
                :success="!$v.trip.dates.$invalid"
                @click="isClose = false"
              >
                <template v-slot:prepend-inner>
                  <img
                    style="margin-top: 5px"
                    src="@/assets/img/iconsSvg/calendarIcon.svg"
                    width="24px"
                    height="24px"
                  />
                </template>
              </v-text-field>
            </template>
            <v-row no-gutters align="center" justify="center">
              <v-date-picker
                v-model="trip.dates[0]"
                color="#144FA9"
                locale="uk"
                no-title
                class="disabledPickerBtn"
                :min="
                  new Date(
                    new Date().getTime() +
                      1 * 60 * 60 * 1000 -
                      new Date().getTimezoneOffset() * 60000
                  )
                    .toISOString()
                    .substr(0, 10)
                "
              ></v-date-picker>
              <v-date-picker
                v-model="trip.dates[1]"
                color="#144FA9"
                locale="uk"
                class="disabledPickerBtn"
                no-title
                :min="
                  trip.dates[0]
                    ? new Date(trip.dates[0]).toISOString().substr(0, 10)
                    : new Date(
                        new Date().getTime() +
                          1 * 60 * 60 * 1000 -
                          new Date().getTimezoneOffset() * 60000
                      )
                        .toISOString()
                        .substr(0, 10)
                "
              ></v-date-picker>
            </v-row>
          </v-menu>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="6">
          <v-autocomplete
            outlined
            dense
            placeholder="Виберіть тут.."
            label="Автобус"
            height="50px"
            background-color="#FCFCFC"
            color="#144FA9"
            v-model="trip.bus"
            style="border-radius: 10px"
            :items="Object.values(busList)"
            :item-text="(bus) => `${bus.manufacturer} ${bus.model}`"
            :item-value="'id'"
            :error-messages="busError"
            :success="!$v.trip.bus.$invalid"
          />
        </v-col>
        <v-col cols="6">
          <v-menu
            ref="menu"
            :close-on-content-click="isClose"
            offset-y
            nudge-left="0"
            nudge-bottom="0"
            width="200px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-bind="attrs"
                v-on="on"
                outlined
                dense
                placeholder="Виберіть тут.."
                label="Дати повторення"
                height="50px"
                background-color="#FCFCFC"
                color="#144FA9"
                v-model="trip.repitDates"
                style="padding-left: 20px; border-radius: 10px"
                @click="isClose = false"
                :disabled="trip.dates.length < 2"
              >
                <template v-slot:prepend-inner>
                  <img
                    style="margin-top: 5px"
                    src="@/assets/img/iconsSvg/calendarIcon.svg"
                    width="24px"
                    height="24px"
                  />
                </template>
              </v-text-field>
            </template>
            <v-row no-gutters align="center" justify="center">
              <v-date-picker
                v-if="trip.dates.length > 1"
                v-model="trip.repitDates"
                color="#144FA9"
                locale="uk"
                no-title
                class="disabledPickerBtn"
                multiple
                :min="trip.dates[1]"
              ></v-date-picker>
            </v-row>
          </v-menu>
        </v-col>
      </v-row>
    </div>
    <div class="sectionBackground">
      <p class="sectionTitle">Місця в автобусі</p>
      <p class="sectionText">Позначте місця, які були завчасно зарезервовані</p>
      <div class="sectionLine" />
      <v-row
        no-gutters
        justify="center"
        style="margin-top: 32px"
        v-if="quantityBusSeats == 0"
      >
        <p class="adminSectionName">Спочатку Виберіть автобус</p>
      </v-row>
      <v-row no-gutters align="center" style="margin-top: 32px">
        <div class="seatsBlock" style="background: #149e51"></div>
        <span class="label"
          >-Вільне ({{
            seats.length - disabledSeats.length - selectedSeats.length
          }})</span
        >
        <div
          class="seatsBlock"
          style="background: #144fa9; margin-left: 32px"
        ></div>
        <span class="label">-Вибрано ({{ selectedSeats.length }})</span>
        <div
          class="seatsBlock"
          style="background: #6b7c8a; margin-left: 32px"
        ></div>
        <span class="label">-Недоступно ({{ disabledSeats.length }})</span>
        <div
          class="seatsBlock"
          style="background: #fbbb55; margin-left: 32px"
        ></div>
        <span class="label">-Заброньовано ({{ bookingSeats.length }})</span>
      </v-row>
      <loader v-if="showLoader" />
      <v-row
        v-show="!showLoader"
        no-gutters
        align="start"
        justify="start"
        style="margin-top: 32px"
      >
        <div
          style="width: 100px"
          class="quantitySeatsBlock"
          v-for="index in quantityBlock"
          :key="index"
        >
          <v-item-group v-model="selectedSeats" multiple>
            <v-row no-gutters align="center">
              <v-item
                v-for="seats in seats.slice(index * 10 - 10, index * 10)"
                :key="seats.id"
              >
                <div
                  style="margin-right: 10px; margin-bottom: 10px"
                  class="pointer seatsBlock"
                  :style="
                    disabledSeats.includes(seats.id)
                      ? 'background: #6B7C8A; pointer-events: none;'
                      : selectedSeats.includes(seats.id)
                      ? 'background: #144FA9'
                      : bookingSeats.includes(seats.id)
                      ? 'background: #FBBB55'
                      : 'background: #149E51'
                  "
                  @click="
                    selectedSeats.includes(seats.id)
                      ? removeForSelectedList(seats.id)
                      : selectedSeats.push(seats.id)
                  "
                >
                  {{ seats.number_seats }}
                </div>
              </v-item>
            </v-row>
          </v-item-group>
        </div>
      </v-row>
      <p
        v-if="!trip.bus && !showLoader"
        style="
          font-weight: 600;
          font-size: 24px;
          line-height: 28px;
          text-align: center;
          color: #1b1b1b;
          margin: 40px 0px;
        "
      >
        Виберіть автобус
      </p>
      <p
        style="
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          text-align: center;
          text-transform: uppercase;
          color: #ff5252;
        "
        v-else-if="selectedSeatsError"
      >
        {{ selectedSeatsError }}
      </p>
    </div>
    <div class="sectionBackground">
      <p class="sectionTitle">Додаткові можливості</p>
      <div class="sectionLine" />
      <v-row no-gutters align="center">
        <v-col class="px-0 py-0">
          <p class="sectionText" style="color: #1b1b1b; margin-bottom: 4px">
            Бали за покупку
          </p>
          <p class="sectionText" style="font-size: 14px">
            Включіть, щоб клієнти за покупку квитка отримували бали.
          </p>
        </v-col>
        <v-row no-gutters justify="end">
          <v-switch inset v-model="trip.is_add_points" />
        </v-row>
      </v-row>
    </div>
    <submit-button
      :loading="isLoadingBtn"
      style="margin-top: 40px; font-weight: 600; height: 48px !important"
      :disabled="$v.$invalid || invalidRoutePrice"
      @click="isEdit ? updateSheduleItem() : createSheduleItem()"
      >{{ isEdit ? "Оновити рейс" : "Створити рейс" }}
    </submit-button>
    <v-row no-gutters justify="center" style="margin-top: 10px">
      <cancel-btn
        text="Скасувати"
        @click="$router.push({ name: 'tripsComponent' })"
      />
    </v-row>
  </v-col>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import CancelBtn from "../../UI/Buttons/cancelBtn.vue";
import submitButton from "../../UI/Buttons/submitButton.vue";
import StatisticCard from "../../UI/Cards/statisticCard.vue";
import tripService from "./../../../requests/Admin/tripService.js";
import Loader from "./../../UI/Loader.vue";
import routesService from "./../../../requests/Admin/routesService.js";
import busService from "./../../../requests/Bus/busService.js";
export default {
  components: { submitButton, CancelBtn, StatisticCard, Loader },
  mixins: [validationMixin],
  data: () => ({
    selectedSeats: [],
    quantityBlock: 0,
    disabledSeats: [],
    bookingSeats: [],
    seats: [],
    isClose: true,
    showLoader: true,
    showMainLoader: true,
    showConfirmModal: false,
    showConfirmDeleteModal: false,
    showLoadingModal: false,
    menu: false,
    invalidRoutePrice: false,
    showSuccessModal: false,
    isLoadingBtn: false,
    quantityBusSeats: 70,
    dayDifference: 0,
    page: 1,
    selectedSeatsError: "",
    trip: {
      dates: [],
      repitDates: [],
    },
    routesList: [],
    busList: [],
    isEdit: false,
  }),
  validations: {
    trip: {
      route: {
        required,
      },
      dates: {
        required,
      },
      bus: {
        required,
      },
    },
  },
  mounted() {
    this.quantityBlock = parseInt(this.quantityBusSeats / 10) + 1;
    this.getRoutes();
    this.getBusses();
    if (this.$route.name == "editTrip") {
      this.getTrip();
      this.isEdit = true;
    } else {
      this.showMainLoader = false;
      this.showLoader = false;
    }
  },
  methods: {
    removeForSelectedList(id) {
      this.selectedSeats = this.selectedSeats.filter(
        (seatsId) => seatsId !== id
      );
    },
    setSeats() {
      this.seats = [];
      for (let index = 0; index < this.quantityBusSeats; index++) {
        this.seats.push({
          id: index + 1,
          number_seats: index + 1,
          disabled: false,
        });
        setTimeout(() => {
          this.showLoader = false;
        }, 1000);
      }
    },
    async getTrip() {
      await tripService.getTripById(this.$route.params.id).then((res) => {
        if (res.status == "Success") {
          this.setFieldForEdit(res.data);
        }
      });
    },
    async createSheduleItem() {
      this.$v.$touch();
      if (this.selectedSeats.length == 0) {
        this.selectedSeatsError = "Потрібно обрати хоча б одно місце *";
      }
      if (!this.$v.$invalid && this.selectedSeats.length > 0) {
        this.isLoadingBtn = true;
        let form = new FormData();
        let start_date = new Date(this.trip.dates[0]);
        let end_date = new Date(this.trip.dates[1]);
        let departure_date = start_date.toISOString().substring(0, 10);
        let arrival_date = end_date.toISOString().substring(0, 10);
        form.append("route_uuid", this.trip.route);
        form.append("bus_id", this.trip.bus);
        form.append("seats", this.selectedSeats.length);
        form.append("seats_available", this.selectedSeats.length);
        form.append("departed_at", departure_date);
        form.append("arrival_at", arrival_date);
        await tripService.createTrip(form).then((res) => {
          if (res.status == "Success" && !this.trip.repitDates.length > 0) {
            this.$router.push({ name: "tripsComponent" });
          }
        });
        if (this.trip.repitDates.length > 0) {
          this.trip.repitDates.forEach(async (date, index) => {
            let form = new FormData();
            form.append("route_uuid", this.trip.route);
            form.append("bus_id", this.trip.bus);
            form.append("seats", this.selectedSeats.length);
            form.append("seats_available", this.selectedSeats.length);
            form.append("departed_at", date);
            form.append("arrival_at", this.addDate(date));
            await tripService.createTrip(form).then((res) => {
              if (
                res.status == "Success" &&
                index == this.trip.repitDates.length - 1
              ) {
                this.$router.push({ name: "tripsComponent" });
              }
            });
          });
        }
      }
    },
    async updateSheduleItem() {
      this.$v.$touch();
      if (this.selectedSeats.length == 0 && this.disabledSeats == 0) {
        this.selectedSeatsError = "Потрібно обрати хоча б одно місце *";
      }
      if (
        !this.$v.$invalid &&
        (this.selectedSeats.length > 0 || this.disabledSeats.length > 0)
      ) {
        this.isLoadingBtn = true;
        let form = new FormData();
        let start_date = new Date(this.trip.dates[0]);
        let end_date = new Date(this.trip.dates[1]);
        let departure_date = start_date.toISOString().substring(0, 10);
        let arrival_date = end_date.toISOString().substring(0, 10);
        form.append("route_uuid", this.trip.route);
        form.append("bus_id", this.trip.bus);
        form.append(
          "seats",
          this.selectedSeats.length + this.disabledSeats.length
        );
        form.append("seats_available", this.selectedSeats.length);
        form.append("departed_at", departure_date);
        form.append("arrival_at", arrival_date);
        await tripService.updateTrip(this.trip.id, form).then((res) => {
          if (res.status == "Success" && !this.trip.repitDates.length > 0) {
            this.showSuccessModal = true;
          }
        });
        if (this.trip.repitDates.length > 0) {
          this.trip.repitDates.forEach(async (date, index) => {
            let form = new FormData();
            form.append("route_uuid", this.trip.route);
            form.append("bus_id", this.trip.bus);
            form.append("seats", this.selectedSeats.length);
            form.append("seats_available", this.selectedSeats.length);
            form.append("departed_at", date);
            form.append("arrival_at", this.addDate(date));
            await tripService.createTrip(form).then((res) => {
              if (
                res.status == "Success" &&
                index == this.trip.repitDates.length - 1
              ) {
                this.$router.push({ name: "tripsComponent" });
              }
            });
          });
        }
      }
    },
    setFieldForEdit(item) {
      this.$set(this.trip, "id", item.id);
      this.$set(this.trip, "route", item.route.id);
      this.$set(this.trip, "bus", item.bus.id);
      this.$set(this.trip, "seats", item.seats);
      this.$set(this.trip, "seats_available", item.seats_available);
      this.$set(this.trip, "dates", [
        item.departed_at.slice(0, 10),
        item.arrival_at.slice(0, 10),
      ]);
      item.seats;
      this.setSeatsForEdit(item);
      this.showMainLoader = false;
    },
    setSeatsForEdit(item) {
      for (let index = 1; index < item.seats + 1; index++) {
        if (index < item.seats - item.seats_available + 1) {
          this.disabledSeats.push(index);
        } else {
          this.selectedSeats.push(index);
        }
      }
    },
    async getRoutes() {
      await routesService
        .getRouteForAdmin(this.page, "", "", "")
        .then((res) => {
          if (res.status == "Success") {
            res.data.forEach((item) => {
              this.routesList.push(item);
            });
            if (this.page < res.data.pagination.meta.last_page) {
              this.page++;
              this.getRoutes();
            }
          }
        });
    },
    async getBusses() {
      await busService
        .getBusesForAdmin("", "[status[eq]: Active]", 0)
        .then((res) => {
          if (res.status == "Success") {
            this.busList = res.data;
          }
        });
    },
    async cancelTrip() {
      let form = new FormData();
      form.append("status", "Archive");
      await tripService.changeTripStatus(this.trip.id, form).then((res) => {
        if (res.status == "Success") {
          this.$router.push({ name: "tripsComponent" });
        }
      });
    },
    // async deleteTrip() {
    //   await tripService.deleteTrip(this.trip.id).then((res) => {
    //     if (res.status == "Success") {
    //       (this.showConfirmModal = false), this.$emit("back");
    //     }
    //   });
    // },
    addDate(start_date) {
      if (start_date) {
        const newDate = new Date(start_date);
        newDate.setDate(newDate.getDate() + this.dayDifference);
        return newDate.toISOString().substring(0, 10);
      }
    },
    subtractDate() {
      if (this.trip.dates.length > 1) {
        const start = new Date(this.trip.dates[0]);
        const end = new Date(this.trip.dates[1]);

        const difference = Math.abs(end - start);

        this.dayDifference = Math.floor(difference / (1000 * 60 * 60 * 24));
        console.log("dif", this.dayDifference);
      }
    },
  },
  computed: {
    routeError() {
      const errors = [];
      if (!this.$v.trip.route.$dirty) {
        return errors;
      }
      !this.$v.trip.route.required && errors.push("");
      return errors;
    },
    datesError() {
      const errors = [];
      if (!this.$v.trip.dates.$dirty) {
        return errors;
      }
      !this.$v.trip.dates.required && errors.push("");
      return errors;
    },
    busError() {
      const errors = [];
      if (!this.$v.trip.bus.$dirty) {
        return errors;
      }
      !this.$v.trip.bus.required && errors.push("");
      return errors;
    },
  },
  watch: {
    "trip.bus": {
      handler() {
        this.showLoader = true;
        setTimeout(() => {
          if (this.trip.bus !== "" && this.trip.bus !== undefined) {
            let bus = this.busList.filter((bus) => bus.id == this.trip.bus);
            this.quantityBusSeats = bus[0].seats;
            this.setSeats();
          }
        }, 100);
      },
    },
    "trip.route": {
      handler() {
        let price = this.routesList.filter(
          (route) => route.id == this.trip.route
        )?.[0]?.prices;
        price.findIndex((price) => price.price == 0) == -1
          ? (this.invalidRoutePrice = false)
          : (this.invalidRoutePrice = true);
      },
    },
    "trip.dates": {
      handler() {
        if (this.trip.dates[1] < this.trip.dates[0]) {
          this.trip.dates[1] = "";
        }
        this.subtractDate();
        if (typeof this.trip.dates == "string") {
          if (this.trip.dates.length > 10) {
            let trip = this.trip.dates;
            this.trip.dates = [];
            this.trip.dates[0] = `${trip.substr(0, 10)}`;
            this.trip.dates[1] = "";
          } else {
            this.trip.dates = [];
          }
        }
      },
    },
    tripForEdit: {
      deep: true,
      handler() {
        if (this.isEdit) {
          this.setFieldForEdit();
        }
      },
    },
    selectedSeats: {
      deep: true,
      handler() {
        if (this.selectedSeats.length > 0) {
          this.selectedSeatsError = "";
        }
      },
    },
  },
};
</script>

<style scoped>
.quantitySeatsBlock {
  margin-right: 12px;
}
.quantitySeatsBlock:nth-child(7n) {
  margin-right: 0px;
}
.seatsBlock {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 5px;
  user-select: none;
}
.label {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.1em;
  color: #243949;
  margin-left: 10px;
}
.deleteTripBtn {
  user-select: none;
  cursor: pointer;
  color: #df3a3a;
  font-family: "MacPaw Fixel";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: none;
  background: rgba(223, 58, 58, 0.1) !important;
  border-radius: 10px !important;
}
</style>